/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hdd-rack': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.5 5a.5.5 0 100-1 .5.5 0 000 1M3 4.5a.5.5 0 11-1 0 .5.5 0 011 0m2 7a.5.5 0 11-1 0 .5.5 0 011 0m-2.5.5a.5.5 0 100-1 .5.5 0 000 1"/><path pid="1" d="M2 2a2 2 0 00-2 2v1a2 2 0 002 2h1v2H2a2 2 0 00-2 2v1a2 2 0 002 2h12a2 2 0 002-2v-1a2 2 0 00-2-2h-1V7h1a2 2 0 002-2V4a2 2 0 00-2-2zm13 2v1a1 1 0 01-1 1H2a1 1 0 01-1-1V4a1 1 0 011-1h12a1 1 0 011 1m0 7v1a1 1 0 01-1 1H2a1 1 0 01-1-1v-1a1 1 0 011-1h12a1 1 0 011 1m-3-4v2H4V7z"/>',
    },
});
